import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "InstarVision Surveillance Center",
  "path": "/Software/iOS/InstarVision/Wizard/iPhone/Add_Camera/New_IP_Cam/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Um mit unserer Android App InstarVision auf Ihre IP-Kamera zuzugreifen, empfehlen wir Ihnen, zuerst die neueste Version aus dem App Store herunterzuladen. Verwenden Sie das Suchwort Instar, um unsere App im Geschäft zu finden.",
  "image": "./P_SearchThumb_InstarVision_Android.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_Android.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Android-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "iphone-app-installationsassistent",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iphone-app-installationsassistent",
        "aria-label": "iphone app installationsassistent permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`iPhone App Installationsassistent`}</h2>
    <h3 {...{
      "id": "hinzufügen-einer-neuen-ip-kamera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hinzuf%C3%BCgen-einer-neuen-ip-kamera",
        "aria-label": "hinzufügen einer neuen ip kamera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Hinzufügen einer neuen IP Kamera`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/103945b1669f74e4bdaa3a1afe7dfee6/81315/iPhone_Wizard_New_IP_Cam_d01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAACgUlEQVQ4y41VCW4bMQzU/1/XokCBomnT+Nx7dUurawrR3o2duE0MDCSTI4oUOTZTSmPoB+RUEEJGziCEmJFSQd+PEELD+wVt0yLGTKicUkCcCs4l+mECO/OIl2GBsgklFzRNR4ixIIYEZQJOc0QrEvbjgiVkeLdgtzvg+fmFAtWA1gXsxwDGhcMsPIR0UMphnjXmWdFeku3iqzwuPNmktJgmhWmS4NwQt6LyWHWuEMJeDzjar3jLEeLCkcrfcauf1ajiGuTeeWO7wXueveOx24OP8Brc3mX9L7BHJd6Xuwa7z/Dh5TXD18OPD6xB+axwPrUbDodmw/HY4bA/Yxw5WO2ONR5DP+F4rM4W+/2Z0LYTdU9Xjs+QHpC+QPmCnAtKuawpJRifYJcMJmxCncVORnRiQYyRCCnlDUssmJXFjz8NnnYtfu1adH2Ptm3R9z2cc/g5Ar8ngCnj0AwcI9foJgGlFHKVAUAZVIQE5BgQnEbwBoszMMZAaw3nPHFtKHChgGmfMZoM5RNQMmWXc6Kga8CYCpYE6AioAITLfduncraAXBqceo5uFOBcYJ45OOd0++V9MmXYTwJfvz/hadfgeXdC0zRUcnM+E/fLGfjWAKw+uNEOWrtNQivWThvtYY0Fnycs3mHxnkqtb1fXWk1tyKUp4nMDWzWtzULrW5Dmr/hQKR8N/j+V8rH0Pgd2K+5VZu9tt77/fSfpuYvsaiarBMWjEt/zHj0Fu81IbL95ZrvxteOf4zEpJZzRGEzBaIHFWRhjEUKAtZb+S7z3F2UoidkWdBqIoXZYkxDqHIYQicPSVRUpFxrsqs1h6CEEJzv5Utr2uRQIqTAQb8A8z5u/rn8BYdSf0rN0of4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/103945b1669f74e4bdaa3a1afe7dfee6/e4706/iPhone_Wizard_New_IP_Cam_d01.avif 230w", "/en/static/103945b1669f74e4bdaa3a1afe7dfee6/d1af7/iPhone_Wizard_New_IP_Cam_d01.avif 460w", "/en/static/103945b1669f74e4bdaa3a1afe7dfee6/7f308/iPhone_Wizard_New_IP_Cam_d01.avif 920w", "/en/static/103945b1669f74e4bdaa3a1afe7dfee6/e1c99/iPhone_Wizard_New_IP_Cam_d01.avif 1380w", "/en/static/103945b1669f74e4bdaa3a1afe7dfee6/6e1e4/iPhone_Wizard_New_IP_Cam_d01.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/103945b1669f74e4bdaa3a1afe7dfee6/a0b58/iPhone_Wizard_New_IP_Cam_d01.webp 230w", "/en/static/103945b1669f74e4bdaa3a1afe7dfee6/bc10c/iPhone_Wizard_New_IP_Cam_d01.webp 460w", "/en/static/103945b1669f74e4bdaa3a1afe7dfee6/966d8/iPhone_Wizard_New_IP_Cam_d01.webp 920w", "/en/static/103945b1669f74e4bdaa3a1afe7dfee6/445df/iPhone_Wizard_New_IP_Cam_d01.webp 1380w", "/en/static/103945b1669f74e4bdaa3a1afe7dfee6/87a8c/iPhone_Wizard_New_IP_Cam_d01.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/103945b1669f74e4bdaa3a1afe7dfee6/81c8e/iPhone_Wizard_New_IP_Cam_d01.png 230w", "/en/static/103945b1669f74e4bdaa3a1afe7dfee6/08a84/iPhone_Wizard_New_IP_Cam_d01.png 460w", "/en/static/103945b1669f74e4bdaa3a1afe7dfee6/c0255/iPhone_Wizard_New_IP_Cam_d01.png 920w", "/en/static/103945b1669f74e4bdaa3a1afe7dfee6/b1001/iPhone_Wizard_New_IP_Cam_d01.png 1380w", "/en/static/103945b1669f74e4bdaa3a1afe7dfee6/81315/iPhone_Wizard_New_IP_Cam_d01.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/103945b1669f74e4bdaa3a1afe7dfee6/c0255/iPhone_Wizard_New_IP_Cam_d01.png",
              "alt": "InstarVision iPhone Installation Wizard",
              "title": "InstarVision iPhone Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[01]`}</strong>{` Beginnen Sie mit dem Aufruf des Menüs `}<strong parentName="li">{`Kameras`}</strong>{` und wählen Sie die Option `}<strong parentName="li">{`Kamera hinzufügen`}</strong>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`[02]`}</strong>{` Sie können nun mit dem Hinzufügen des Fernzugriffs über den Dienst `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPhone/P2P/"
        }}>{`Point2Point (P2P)`}</a>{` oder `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPhone/DDNS/"
        }}>{`DDNS`}</a>{` fortfahren.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8876c6a2ee17070815c9257d8b38b809/81315/iPhone_Wizard_New_IP_Cam_d02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAACQUlEQVQ4y41Vy27aUBQ076fCG8RjkWUW/Hmz6Cc03VSADSQKoSpdJFUWhUKDMDYP20w1J70WlQrY0sjH1zrjOWfOvda0UARaNAlN004inU7D0HUs335ju7Gx2+3gOI6A8X6/x2KxwGw2g6ZFEwhlitBC4ZOEqVQKn74M8XPl4cfcxnzxhslkgsnkO6bTKbbbLdbrtUA7pywUCvmEH24/otO7x/B+jNHTNzw8PAkeH79iPJ74OEt4XPLd3WesViuYKxOmSTWWwDQteVbQlJJisYhKpYJqtYpyuSx3PivC4XAo/fI8F+cuIcxmswImZjIZH2qN6Pf72Gw2YsThcDgJIWTyuXL53jAM2LYNz/MuE1JJOBz2jTgGDSFht9uFZVmSdI7UJ1SO/k+hzKFhiCmcuYsKmUCFhUJBUCqVBPxIMpmU94PBAKZpijEXCfP5vKhsNBqo1WqCVqslpV5dXfkK310O0MPr62sh473dbuPm5sZfq9frvsu8AhEqlxOJhJTMOWSpxy4rUwIRMimXy0nZJCIYc031UNd12bOBCFUfuTvUM2Ma4582f+fQdd1ghFRzTEgyrilC9pAlB3KZw8sS6W6z2RTQDBKyr+whSyZhoK0Xj8clkWDPVMwPRaNRIez1enLeuUF7eOn46nQ6WC6XwXt47oBVPaTDe8cRUtd9/wUw5rpCYIW6bgCei4XlYm4DB8+BZdsy7Kq3nIJ/CCORCGKxmIDx8T9lNBpJsuN6mP1a4Pn5GS8vL3h9fZUDg+qo9g/POD5eWzF7YAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8876c6a2ee17070815c9257d8b38b809/e4706/iPhone_Wizard_New_IP_Cam_d02.avif 230w", "/en/static/8876c6a2ee17070815c9257d8b38b809/d1af7/iPhone_Wizard_New_IP_Cam_d02.avif 460w", "/en/static/8876c6a2ee17070815c9257d8b38b809/7f308/iPhone_Wizard_New_IP_Cam_d02.avif 920w", "/en/static/8876c6a2ee17070815c9257d8b38b809/e1c99/iPhone_Wizard_New_IP_Cam_d02.avif 1380w", "/en/static/8876c6a2ee17070815c9257d8b38b809/6e1e4/iPhone_Wizard_New_IP_Cam_d02.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8876c6a2ee17070815c9257d8b38b809/a0b58/iPhone_Wizard_New_IP_Cam_d02.webp 230w", "/en/static/8876c6a2ee17070815c9257d8b38b809/bc10c/iPhone_Wizard_New_IP_Cam_d02.webp 460w", "/en/static/8876c6a2ee17070815c9257d8b38b809/966d8/iPhone_Wizard_New_IP_Cam_d02.webp 920w", "/en/static/8876c6a2ee17070815c9257d8b38b809/445df/iPhone_Wizard_New_IP_Cam_d02.webp 1380w", "/en/static/8876c6a2ee17070815c9257d8b38b809/87a8c/iPhone_Wizard_New_IP_Cam_d02.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8876c6a2ee17070815c9257d8b38b809/81c8e/iPhone_Wizard_New_IP_Cam_d02.png 230w", "/en/static/8876c6a2ee17070815c9257d8b38b809/08a84/iPhone_Wizard_New_IP_Cam_d02.png 460w", "/en/static/8876c6a2ee17070815c9257d8b38b809/c0255/iPhone_Wizard_New_IP_Cam_d02.png 920w", "/en/static/8876c6a2ee17070815c9257d8b38b809/b1001/iPhone_Wizard_New_IP_Cam_d02.png 1380w", "/en/static/8876c6a2ee17070815c9257d8b38b809/81315/iPhone_Wizard_New_IP_Cam_d02.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8876c6a2ee17070815c9257d8b38b809/c0255/iPhone_Wizard_New_IP_Cam_d02.png",
              "alt": "InstarVision iPhone Installation Wizard",
              "title": "InstarVision iPhone Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[03]`}</strong>{` Verwenden Sie die App, um den QR-Code von Ihrer Kamera oder Ihrem Kamerapaket zu scannen. Wenn Ihre Kamera keinen QR-Code hat, wählen Sie bitte im nächsten Schritt das Kameramodell manuell aus.`}</li>
      <li parentName="ul"><strong parentName="li">{`[04]`}</strong>{` Wählen Sie Ihren Kamerahersteller, z.B. `}<strong parentName="li">{`INSTAR`}</strong></li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4cceb311672ff531b16e5d4290d39e24/81315/iPhone_Wizard_New_IP_Cam_d03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAACSklEQVQ4y6VVaW+rMBDk///A5EPUltwc4bbBB3iqWUISpcnTq2ppZMDr2dlde4nqukJ6PsFZA2sNnHMCa60gSRJUVQWtFdLkDG8NjBlkjXbDMD8XRYE0TREdS4M4H5C0I9K8wNfXJz4+PpFlmWxQSmEYeiTVgDhTOJQGpfLIswy73Q7GGDhrobRGnPeIyrrB/ngWUAkX6ZVk0zRhmsKs2HkEAHqwqNsOTdOgrmt0XYe+78V2nAKipq5xOh0xeg8EbrmPcH333otKpTp0XSuqCRItsxCOIyJ6YHh5nssCBxdJtoCGXF+v19jv9z8cznYT1OBnQhozsc9EywYSUknbthImHVP1UkCC78Y6RDRK00xywQTfPd5BR3S6Wq2w2WygtX4ZiTIeET0yDBaExXgmIxY1izMq5jfvx6tCPxeQOSTh4XAQhTR6pXDePK+N3qEqC5TFBW1T4ZKn0Kq77sMSciq5eR0yRME4evgJaIaAxgQUOshzayDQjvkO9ypTwauiLDlkFYXQBCiLGxFB4l4y8kBIsJKvQhbMK8uBeYF5n+TwdDpJot+SPYR/n3+CQwiPxyPO5/NN4bvQHw/zuyGEJPu3sl8QUtXlcpE2xXP4Z4Uk5KF+vJ9/UshzyGvFBvmuOfwGEdXxpvB+eu/eqvzfISGXZYleWrm73VPOc4N9RsB47X2PNjeFDFmrFknjUAzA6CwGY8SIKVj+Lbrvxa7qAy49ECYv3zjm6LzYRzfPAUiSFHEcC3jYpyclnBl9lhdis91uJV3L74Id+xvLM7hgOr4JBAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4cceb311672ff531b16e5d4290d39e24/e4706/iPhone_Wizard_New_IP_Cam_d03.avif 230w", "/en/static/4cceb311672ff531b16e5d4290d39e24/d1af7/iPhone_Wizard_New_IP_Cam_d03.avif 460w", "/en/static/4cceb311672ff531b16e5d4290d39e24/7f308/iPhone_Wizard_New_IP_Cam_d03.avif 920w", "/en/static/4cceb311672ff531b16e5d4290d39e24/e1c99/iPhone_Wizard_New_IP_Cam_d03.avif 1380w", "/en/static/4cceb311672ff531b16e5d4290d39e24/6e1e4/iPhone_Wizard_New_IP_Cam_d03.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4cceb311672ff531b16e5d4290d39e24/a0b58/iPhone_Wizard_New_IP_Cam_d03.webp 230w", "/en/static/4cceb311672ff531b16e5d4290d39e24/bc10c/iPhone_Wizard_New_IP_Cam_d03.webp 460w", "/en/static/4cceb311672ff531b16e5d4290d39e24/966d8/iPhone_Wizard_New_IP_Cam_d03.webp 920w", "/en/static/4cceb311672ff531b16e5d4290d39e24/445df/iPhone_Wizard_New_IP_Cam_d03.webp 1380w", "/en/static/4cceb311672ff531b16e5d4290d39e24/87a8c/iPhone_Wizard_New_IP_Cam_d03.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4cceb311672ff531b16e5d4290d39e24/81c8e/iPhone_Wizard_New_IP_Cam_d03.png 230w", "/en/static/4cceb311672ff531b16e5d4290d39e24/08a84/iPhone_Wizard_New_IP_Cam_d03.png 460w", "/en/static/4cceb311672ff531b16e5d4290d39e24/c0255/iPhone_Wizard_New_IP_Cam_d03.png 920w", "/en/static/4cceb311672ff531b16e5d4290d39e24/b1001/iPhone_Wizard_New_IP_Cam_d03.png 1380w", "/en/static/4cceb311672ff531b16e5d4290d39e24/81315/iPhone_Wizard_New_IP_Cam_d03.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4cceb311672ff531b16e5d4290d39e24/c0255/iPhone_Wizard_New_IP_Cam_d03.png",
              "alt": "InstarVision iPhone Installation Wizard",
              "title": "InstarVision iPhone Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[05]`}</strong>{` Wählen Sie Ihr Kameramodell, z.B. `}<strong parentName="li">{`IN-8015FullHD`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`[06]`}</strong>{` Sobald Ihre Kamera angeschlossen ist, klicken Sie auf `}<strong parentName="li">{`Suchen`}</strong>{` und die App wird alle INSTAR-Kameras in Ihrem lokalen Netzwerk erkennen. Wenn Ihre Kamera nicht angezeigt wird, wählen Sie die Option `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPad/DDNS/"
        }}>{`manuell hinzufügen`}</a>{`.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4af193811e421baaa5a40f63b791f30e/81315/iPhone_Wizard_New_IP_Cam_d04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAACk0lEQVQ4y61VTW/TQBD1D0LigjgBRw4cQPwuDqhIRSoHhFAPVbnwD5AoSCBxoRWXtkkTiPJRJ7Zje3f9tfvQG8euG6U0B0Z6Wnv27duZ8c7aW0YR/MsZ8jyHMUbG7vN0OkUQhlAqxWw6QZ5l7VxRFO3zYrHAbDaD92du8NtX0NqgLEtMJhOMx2MhZ8YgihVGC4NJaDC8VNAmE958PsdwOBQeBVOlMPA1vKK0KC3EuFscx1gul/JMc86hrKygcjVHa400TRFFUftOXmUdPC7KMiOEMAxFjCPJSikURV4LWwtjtPiDIBBOMxIUtVXVCGZCZLr9fh+np6fwfV+IFG2M75zv9Xo4Pz/H2dmZgL7RaCSl8LiAO/GlSXGTMQJGz40b7ibzGAlRVZWIdUEfQYuiEIPBAP2LIZTJUVmgqFhfh6yopMZc47GoTJm7rgt2Ya0FHMUZnVuDldHyo/CrMkJ++ptStivXMAKe7QOP3wJP3wPP94En74BHb4CDn6uUKcQ63pQyz5l1dSSBdtj95vDySz3ufXfY+erw4rPDj1HN8ZiqLLK2jagr2vXdZsxEapgkiZzDJEnbA03jRkEQSo3bQ26dNAJRrUaiKYtHcpoqJKlCTMEsl0miKCuZo/D1yLERIrg5+ObrdV23p7wSdDi6cLj7yuHersPDPYcHe8D918CdHYdPvVqc6W1TR4lwmgCHx8DhCfDxV40PJ8DBMTCOrwLcJkhvG9KW2V6l3Fw9m+BWZ/BfXdSFh/9sHg90DXaGbS8E+tZ7ueXZ67wu1+OhNjrFQjmEGVAVGUyWCYlXVvOPUVpDpTFC7TDXvHBL8dHYuuw48r1mF9ZrMp3JFUXwv2LXIqn7Hbj0F8LhP4UXa9O6nP8LeZWobGjGQsEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4af193811e421baaa5a40f63b791f30e/e4706/iPhone_Wizard_New_IP_Cam_d04.avif 230w", "/en/static/4af193811e421baaa5a40f63b791f30e/d1af7/iPhone_Wizard_New_IP_Cam_d04.avif 460w", "/en/static/4af193811e421baaa5a40f63b791f30e/7f308/iPhone_Wizard_New_IP_Cam_d04.avif 920w", "/en/static/4af193811e421baaa5a40f63b791f30e/e1c99/iPhone_Wizard_New_IP_Cam_d04.avif 1380w", "/en/static/4af193811e421baaa5a40f63b791f30e/6e1e4/iPhone_Wizard_New_IP_Cam_d04.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4af193811e421baaa5a40f63b791f30e/a0b58/iPhone_Wizard_New_IP_Cam_d04.webp 230w", "/en/static/4af193811e421baaa5a40f63b791f30e/bc10c/iPhone_Wizard_New_IP_Cam_d04.webp 460w", "/en/static/4af193811e421baaa5a40f63b791f30e/966d8/iPhone_Wizard_New_IP_Cam_d04.webp 920w", "/en/static/4af193811e421baaa5a40f63b791f30e/445df/iPhone_Wizard_New_IP_Cam_d04.webp 1380w", "/en/static/4af193811e421baaa5a40f63b791f30e/87a8c/iPhone_Wizard_New_IP_Cam_d04.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4af193811e421baaa5a40f63b791f30e/81c8e/iPhone_Wizard_New_IP_Cam_d04.png 230w", "/en/static/4af193811e421baaa5a40f63b791f30e/08a84/iPhone_Wizard_New_IP_Cam_d04.png 460w", "/en/static/4af193811e421baaa5a40f63b791f30e/c0255/iPhone_Wizard_New_IP_Cam_d04.png 920w", "/en/static/4af193811e421baaa5a40f63b791f30e/b1001/iPhone_Wizard_New_IP_Cam_d04.png 1380w", "/en/static/4af193811e421baaa5a40f63b791f30e/81315/iPhone_Wizard_New_IP_Cam_d04.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4af193811e421baaa5a40f63b791f30e/c0255/iPhone_Wizard_New_IP_Cam_d04.png",
              "alt": "InstarVision iPhone Installation Wizard",
              "title": "InstarVision iPhone Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[07]`}</strong>{` Sie werden nun aufgefordert, den Admin-Login Ihrer Kamera einzugeben. Wenn Sie es vorher nicht eingerichtet haben, wird dies der Standard-Userermane `}<strong parentName="li">{`admin`}</strong>{` und das Passwort `}<strong parentName="li">{`instar`}</strong>{` sein. Dies kann später innerhalb der App geändert werden.`}</li>
      <li parentName="ul"><strong parentName="li">{`[08]`}</strong>{` Bitte wählen Sie einen Namen für Ihre Kamera.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      